import { NgModule } from '@angular/core'
import {
	RouterModule,
	Routes,
	provideRouter,
	withComponentInputBinding,
} from '@angular/router'
import { MessageService } from 'primeng/api'
import { AuthGuard, LoginMadeAuthGuard } from './core/authorization/auth.guard'
import { ErrorPagesComponent } from './shared/components/error-pages/error-pages.component'
import { RoutingPageComponent } from './core/home/components/routing-page/routing-page.component'
import { AuthenticationPageComponent } from './core/authentication/components/authentication-page/authentication-page.component'
import { BackofficeMainPageComponent } from './core/backoffice/components/backoffice-main-page/backoffice-main-page.component'

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('./core/authentication/authentication.module').then(
				(m) => m.AuthenticationModule,
			),
		canActivateChild: [LoginMadeAuthGuard],
		component: AuthenticationPageComponent,
	},
	{
		path: 'backoffice',
		loadChildren: () =>
			import('./core/backoffice/backoffice.module').then(
				(m) => m.BackofficeModule,
			),
		canActivateChild: [AuthGuard],
		component: BackofficeMainPageComponent,
	},
	{
		path: '',
		loadChildren: () =>
			import('./core/home/home.module').then((m) => m.HomeModule),
		component: RoutingPageComponent,
	},
	{
		path: 'unauthorized',
		component: ErrorPagesComponent,
	},
	{
		path: '**',
		component: ErrorPagesComponent,
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [
		MessageService,
		provideRouter(routes, withComponentInputBinding()),
	],
})
export class AppRoutingModule {}
