import { LOCALE_ID } from '@angular/core'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { HttpClient, HttpClientModule } from '@angular/common/http'

import { aesInterceptorProvider } from './core/authorization/encrypt-interceptor/aes.interceptor'
import { MessageService, SharedModule } from 'primeng/api'
import { refreshTokenProvider } from './core/authorization/refresh-token-interceptor/refresh-token.interceptor'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { toastsInterceptorProvider } from './interceptors/toasts.interceptor'
import { ToastModule } from 'primeng/toast'
import { apiVersionInterceptorProvider } from './interceptors/api-version.interceptor'
import { NavbarComponent } from './core/backoffice/components/navbar/navbar.component'
import { ProgressSpinnerModule } from 'primeng/progressspinner'

import { GuardVerification } from './core/authorization/auth.guard'
import { registerLocaleData } from '@angular/common'
import pt from '@angular/common/locales/pt'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { MenuModule } from 'primeng/menu'
import { ButtonModule } from 'primeng/button'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { DropdownModule } from 'primeng/dropdown'
import { FormsModule } from '@angular/forms'
import { ErrorPagesComponent } from './shared/components/error-pages/error-pages.component'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw'
import { LeafletRoutingService } from './shared/map-routing/leaflet-routing.service'

registerLocaleData(pt)

@NgModule({
	declarations: [AppComponent, ErrorPagesComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ToastModule,
		ProgressSpinnerModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
				deps: [HttpClient],
			},
		}),
		MenuModule,
		ButtonModule,
		TieredMenuModule,
		DropdownModule,
		FormsModule,
		LeafletModule,
		LeafletDrawModule,
		BrowserModule,
		SharedModule,
	],
	providers: [
		aesInterceptorProvider,
		MessageService,
		apiVersionInterceptorProvider,
		toastsInterceptorProvider,
		refreshTokenProvider,
		GuardVerification,
		LeafletRoutingService,
		{ provide: LOCALE_ID, useValue: 'pt-PT' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
