<body>
	<div class="fixed w-full h-full background-image"></div>
	<div class="messageContainer">
		@if (accessDenied) {
			<div class="subDiv">
				<img src="/assets/Logo_cb.svg" />
				<h3>{{ 'error.accessDenied.title' | translate }}</h3>
			</div>
			<i class="pi pi-lock" style="color: red"></i>
			<p>{{ 'error.accessDenied.message' | translate }}</p>
			<a href="" (click)="goBack()">{{ 'routes.back' | translate }}</a>
		} @else {
			<div class="subDiv">
				<img src="/assets/Logo_cb.svg" />
				<h3>{{ 'error.pageNotFound.title' | translate }}</h3>
			</div>
			<i class="pi pi-times-circle"></i>
			<div class="subDiv">
				<p>{{ 'error.pageNotFound.message' | translate }}</p>
				<a href="" (click)="goBack()">{{
					'routes.back' | translate
				}}</a>
			</div>
		}
	</div>
</body>
